import React from "react";
import { StyledParagraph, StyledSpacer, StyledTitle } from "../../globalstyle";
import { StyledLateralTextContainerCursor } from "./style";

/**
 *
 * @param {{to: string; title: string; paragraph: string}} Props
 */

const ScrollMode = ({ to, title, paragraph }) => {
  const scrollToRef = (id, smooth = true) => {
    const yOffset = 0;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    //console.log(y)
    window.scrollTo({
      top: y,
      behavior: "smooth", 
    });
  };

  return (
    <StyledLateralTextContainerCursor
      onClick={() => {
        scrollToRef(to);
      }}
    >
      <StyledTitle
        mini
        color="white"
        font="sans"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <StyledSpacer size={1} />
      <StyledParagraph
        mini
        color="grayDark"
        dangerouslySetInnerHTML={{ __html: paragraph }}
      />
    </StyledLateralTextContainerCursor>
  );
};

export default ScrollMode;
